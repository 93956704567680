import logo from './hooped_in_logo.png'
import Mailchimp from 'react-mailchimp-form'

export default function App() {
  const myComponentStyle = {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
  }
  return (
    <div style={myComponentStyle}>
      <img src={logo} alt='hooped in logo' style={{height: '400px', width: '400px'}}></img>
      <Mailchimp
      action={process.env.REACT_APP_MAILCHIMP_ACTION}
      fields={[
        {
          name: 'EMAIL',
          placeholder: 'Email',
          type: 'email',
          required: true
        }
      ]}
      className='MailchimpStyle'
      messages = {
        {
          sending: "Sending...",
          success: "Thank you for subscribing!",
          error: "An unexpected internal error has occurred.",
          empty: "You must write an e-mail.",
          duplicate: "Too many subscribe attempts for this email address",
          button: "Send Me Updates!"
        }
      }
      />
    </div>
  );
}